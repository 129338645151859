.btn_logout {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10000;
}

body {
    height: 100%;
    background-color: #f9fafb;
}

.card {
    width: 100% !important;
    max-width: 500px;
}

.modal-dialog {
    max-width: 40% !important;
    top: 10%;
}
